<template>
  <div class="left-section">
    <div class="d-flex flex-column pt-5" style="width: 320px;">
      <span class="white--text font-24 app-medium-font mb-3">Send message</span>
      <agency-message-list-card
        :title="category.title"
        :image="category.image"
        :image_selected="category.image_selected"
        v-for="category in categories"
        :key="category.title"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AgencyMessageListCard from "./AgencyMessageListCard";
export default {
  components: { AgencyMessageListCard },
  data() {
    return {
      categories: [
        {
          title: "Email",
          image: require("@/assets/svg/message/icon_email.svg"),
          image_selected: require("@/assets/svg/message/icon_email_selected.svg"),
        },
        {
          title: "SMS",
          image: require("@/assets/svg/message/icon_sms.svg"),
          image_selected: require("@/assets/svg/message/icon_sms_selected.svg"),
        },
        // {
        //   title: "Chat",
        //   image: require("@/assets/svg/message/icon_chat.svg"),
        // },
        {
          title: "History",
          image: require("@/assets/svg/message/icon_history.svg"),
          image_selected: require("@/assets/svg/message/icon_history_selected.svg"),
        },
      ],
    };
  },
  computed: {
    ...mapState("auth", {
      profileType: "type",
    }),
  },
  methods: {},
  mounted() {
    if (!this.$route.query.category) {
      this.$router.replace({
        query: { ...this.$route.query, category: "Email" },
      });
    }
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 56px;
  max-width: 430px;
  min-width: 430px;
  width: 430px;
  padding-left: 100px;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
